import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Segment,
  Tab,
  TabPane,
} from "semantic-ui-react";
import { CommonButton, CommonModal } from "../../../../../../components";
import {
  getModelByBrandsIdService,
  handleConfirmInfoService,
  handleUpdateT6LocationsService,
} from "../../../../../../fetch/services/locations.services";
import {
  InLocationsChargers,
  InLocationsConnector,
  InLocationsItems,
} from "../../../../../../fetch/types/locations.types";
import { ROLES_NAME } from "../../../../../../shared/constants";
import { functionShared } from "../../../../../../shared/functions";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";
import useSessionStore from "../../../../../../store/useSession";
import { useApprovalRejectionContext } from "../../../../../approvalRejection/context";
import { MOCK_INFO_CHARGERS } from "../../../../homeConstant";
import { handleValidationAddConectorSchema } from "../../../../schema/homeModalConfirmation";
import Map from "../../maps";
import { InModalConfirmCPO, InNewConnectors } from "../types";
import GalleryModal from "./modalGallery";
import ScheduleSelectionModal, { InPropsSchedule } from "./modalSchedule";

interface InImagesArray {
  key: "evses" | "locations";
  id: number;
}

interface InImagesEvsesArray extends InImagesArray {
  images: string[] | undefined;
}
interface InNewArrayConnectors {
  evse_uid: number;
  connector: InNewConnectors[];
}

interface InLocationsConnectorExtends extends InLocationsConnector {
  evse_uid: number;
}

interface InLocationsDetails extends InLocationsItems {
  chargers?: number;
  opc?: string;
}

interface InFileUpload {
  config: File;
  folio: File;
}

interface InAllModels {
  key: string;
  values: InModelArray[];
}

interface InModelArray {
  id: number;
  value: string;
  text: string;
}

const DEFAULT_METHOD = {
  API: "A",
  MANUAL: "M",
};
const OPTIONS_TIMEZONE = [
  { key: "Pacific/Easter", text: "Isla de Pascua", value: "Pacific/Easter" },
  {
    key: "America/Punta_Arenas",
    text: "Magallanes",
    value: "America/Punta_Arenas",
  },

  {
    key: "America/Santiago",
    text: "Resto de Chile continental",
    value: "America/Santiago",
  },
];

const MaxValues = {
  max_amperage: 600,
  max_voltage: 1500,
};

const ModalConfirmation = ({
  onClose,
  folio,
  showHeader = true,
}: {
  onClose: () => void;
  folio: InLocationsDetails;
  showHeader?: boolean;
}) => {
  const fileConfigRef = useRef<HTMLInputElement>(null);
  const fileFolioRef = useRef<HTMLInputElement>(null);
  const { handleUpload, handleDownload } = useFileUpload();
  const { profile } = useSessionStore();
  const { brands, parking_types, payments, capabilities } =
    useApprovalRejectionContext();

  const DEFAULT_TAB =
    folio?.evses?.length > 0
      ? folio.evses.sort((a, b) =>
          a.order_number > b.order_number ? 1 : -1
        )[0].evse_uid
      : "";

  const [connectorArray, setConnectorArray] = useState<InLocationsConnector[]>(
    []
  );
  const [isLoading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [activeConnectorTab, setActiveTabConnectorTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [currentIndexCharger, setCurrentIndexCharger] = useState(0);
  const [currentCoords, setCurrentCoords] = useState({
    lat: 0,
    lng: 0,
  });

  const [arrayNewConnector, setArrayNewConnector] = useState<
    InNewArrayConnectors[]
  >([]);

  const [newConnector, setNewConnector] = useState<InNewConnectors>(
    {} as InNewConnectors
  );
  const [newConnectorError, setNewConnectorError] = useState<InNewConnectors>(
    {} as InNewConnectors
  );
  const [renderConnector, setRenderConnector] = useState<
    InNewArrayConnectors[]
  >([]);
  const [currentChargerTab, setCurrentChargerTab] = useState(DEFAULT_TAB);
  const [optionsModel, setOptionsModel] = useState<InAllModels[]>([]);
  const [fileUpload, setFileUpload] = useState({} as InFileUpload);
  const [imagePreview, setImagePreview] = useState(false);
  const [editValuesGeneral, setEditValuesGeneral] = useState<
    InModalConfirmCPO["general"]
  >({} as InModalConfirmCPO["general"]);
  const [generalInfo, setGeneralInfo] = useState<InModalConfirmCPO["general"]>({
    method: folio.api_updates_enabled
      ? DEFAULT_METHOD.API
      : DEFAULT_METHOD.MANUAL,
    parking_type: folio.parking_type,
    time_zone: folio.time_zone,
    charging_when_closed: folio.charging_when_closed,
    twentyfourseven: folio.opening_times.twentyfourseven,
    directions: folio.directions,
    support_phone_number: folio.support_phone_number,
    name: folio.name,
    address: folio.address,
    images: folio.images,
    regular_hours: [],
    coordinates: {
      latitude: Number(folio.coordinates.latitude),
      longitude: Number(folio.coordinates.longitude),
    },
  } as InModalConfirmCPO["general"]);

  const [editCharger, setEditCharger] = useState<
    InModalConfirmCPO["charghers"][]
  >([]);
  const [arrayImagesCharger, setArrayImagesCharger] = useState<
    InImagesEvsesArray[]
  >([]);

  const [showModal, setShowModal] = useState({
    gallery: false,
    schedule: false,
  });

  useEffect(() => {
    const getModelConnector = async () => {
      try {
        const current_brands = folio.evses.map((item) => item.brand);

        const idToSearch: Array<{ id: number; value: string }> = brands
          .filter((element: any) => current_brands.includes(element.value))
          .map((item: any) => {
            return {
              id: item.id,
              value: item.value,
            };
          });

        if (!idToSearch) return;

        const allFetching = idToSearch.map((item) =>
          getModelByBrandsIdService(item.id)
        );
        const promiseAllResult = await Promise.all(allFetching);

        const formatArray = idToSearch.map((item) => {
          const values = promiseAllResult.flatMap((data) =>
            data
              .filter((obj) => obj.brand_id === item.id)
              .map(({ id, value }) => ({ id, value, text: value }))
          );
          return { key: item.value, values };
        });
        setOptionsModel(formatArray);
      } catch (error) {
        toast.error("Ocurrio un error al obtener los modelos de cargadores");
      }
    };
    if (folio.evses.length > 0) {
      getModelConnector();
    }
  }, [folio.evses, brands]);

  useEffect(() => {
    if (folio.evses.length < 1) return;
    const format = folio.evses.map((element) => {
      return {
        evse_uid: element.evse_uid,
        connector: element.connectors,
      };
    });

    setRenderConnector(format);
  }, [currentChargerTab, folio.evses]);

  // useEffect(() => {
  //   // Sincroniza editValuesGeneral con generalInfo al inicio o si generalInfo cambia
  //   setEditValuesGeneral((prev) => ({
  //     ...prev,
  //     twentyfourseven: generalInfo.twentyfourseven,
  //     parking_type: generalInfo.parking_type,
  //     time_zone: generalInfo.time_zone,
  //     directions: generalInfo.directions,
  //     support_phone_number: generalInfo.support_phone_number,
  //     name: generalInfo.name,
  //     address: generalInfo.address,
  //     images: generalInfo.images,
  //     regular_hours: generalInfo.regular_hours,
  //     coordinates: generalInfo.coordinates,
  //   }));
  // }, [generalInfo]);

  useEffect(() => {
    const downloadResources = async () => {
      try {
        setLoadingImg(true);
        const allRequest: InImagesArray[] =
          folio.evses.length > 0
            ? folio.evses.map((item) => ({
                key: "evses",
                id: item.evse_uid,
              }))
            : [];

        allRequest.push({
          key: "locations",
          id: folio.location_id,
        });

        const allFetching = allRequest.map(async (request) => {
          return {
            images: await handleDownload(request.key, request.id),
            id: request.id,
            key: request.key,
          };
        });
        const response = (await Promise.all(allFetching)).filter(
          (element) => !!element.images?.length
        );
        setArrayImagesCharger(response.filter((item) => item.key !== 'locations'));
        for (const iterator of response) {
          if (iterator.key === "locations") {
            setEditValuesGeneral({
              ...editValuesGeneral,
              images:
                iterator.images?.filter(
                  (item) => !item.includes("/images/1.pdf")
                ) || [],
            });
          }
        }
        setLoadingImg(false);
      } catch (error) {
        setLoadingImg(false);

        console.log(error);
      }
    };
    if (folio.location_id) {
      downloadResources();
    }

    // eslint-disable-next-line
  }, [folio]);

  const handleModalCalendar = (type: "gallery" | "schedule") =>
    setShowModal({ ...showModal, [type]: !showModal[type] });

  const handleChargersChange = useCallback(
      (
        key: keyof InModalConfirmCPO["charghers"],
        uid: string | number
      ) =>
      (event: any) => {
        const { value } = event.target;
        const index = Number(uid);

        const formatValue = key === "order_number" || key === "floor_level";
        if (editCharger.find((element) => element?.evse_uid === uid)?.evse_uid) {
          const totalCharger = [...editCharger];
          totalCharger[index] = {
            ...totalCharger[index],
            [key]: formatValue ? Number(value) : value,
          };
          setEditCharger(totalCharger);
        } else {
          const newCharger: any = {
            evse_uid: uid,
            [key]: formatValue ? Number(value) : value,
          };
          const totalCharger = [...editCharger];
          totalCharger[index] = { ...newCharger };
          setEditCharger(totalCharger);
        }
      },
    [editCharger]
  );

  //tTODO: Integrar en una o dos functions
  const handleCheckboxChargersChange = useCallback(
    (
      key: keyof InModalConfirmCPO["charghers"],
      uid: string | number,
      value: boolean
    ) => {
      const index = Number(uid);
      if (editCharger.find((element) => element?.evse_uid === uid)?.evse_uid) {
        const totalCharger = [...editCharger];
        totalCharger[index] = { ...totalCharger[index], [key]: value };
        setEditCharger(totalCharger);
      } else {
        const newCharger: any = {
          evse_uid: uid,
          [key]: value,
        };
        const totalCharger = [...editCharger];
        totalCharger[index] = { ...newCharger };
        setEditCharger(totalCharger);
      }
    },
    [editCharger]
  );

  const handleMapChargerChange = () => {
    const evseFind: any = editCharger.find((element) => element?.evse_uid === currentChargerTab);
    if (evseFind?.evse_uid) {
      const totalCharger = [...editCharger];

      totalCharger[evseFind?.evse_uid] = {
        ...totalCharger[evseFind?.evse_uid],
        coordinates: {
          latitude: currentCoords.lat,
          longitude: currentCoords.lng,
        },
      };
      setEditCharger(totalCharger);
    } else {
      const newCharger: any = {
        evse_uid: currentChargerTab,
        coordinates: {
          latitude: currentCoords.lat,
          longitude: currentCoords.lng,
        },
      };
      const totalCharger = [...editCharger];
      totalCharger[newCharger.evse_uid] = { ...newCharger };
      setEditCharger(totalCharger);
    }
    setOpenMap(false);
  };

  const handleConnectorChange = useCallback(
    (key: keyof InLocationsConnector, uid: number, oldValue = 1) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const _value = ["standard", "format"].includes(key)
          ? String(value)
          : Number(value);

        if (key === "order_number" && typeof _value === "number") {
          const max =
            folio.evses.find((item) => item.evse_uid === currentChargerTab)
              ?.connectors?.length || 1;
          if (!isNaN(_value) && _value < 1)
            return toast.info(`El valor mínimo permitido es de (1)`);

          if (!isNaN(_value) && _value > max)
            return toast.info(`El valor máximo permitido es de (${max})`);
        }

        if (key === "max_electric_power" && typeof _value === "number") {
          if (!isNaN(_value) && _value > Math.round(oldValue * 1.2))
            return toast.info(
              `El valor máximo permitido es de (${Math.round(oldValue * 1.2)})`
            );
        }

        if (
          ["max_amperage", "max_voltage"].includes(key) &&
          typeof _value === "number"
        ) {
          if (!isNaN(_value as any) && _value < 0)
            return toast.info(`El valor mínimo permitido es de (0)`);

          //@ts-ignore
          if (!isNaN(_value as any) && _value > MaxValues[key])
            return toast.info(
              //@ts-ignore
              `El valor máximo permitido es de (${MaxValues[key]})`
            );
        }
        if (
          connectorArray.find((element) => element?.connector_id === uid)
            ?.connector_id
        ) {
          const totalConnector = [...connectorArray];
          totalConnector[uid] = { ...totalConnector[uid], [key]: _value };
          setConnectorArray(totalConnector);
        } else {
          const newConnector: any = {
            evse_uid: Number(currentChargerTab),
            connector_id: uid,
            [key]: _value,
          };
          const totalConnector = [...connectorArray];
          totalConnector[uid] = { ...newConnector };
          setConnectorArray(totalConnector);
        }
      },
    [connectorArray, currentChargerTab, folio.evses]
  );

  const handleChangeOrder = useCallback(
    (
      type: "connector" | "evse",
      uid: number,
      NIndex: number,
      OIndex: number,
      allConnectors: InNewConnectors[],
      allCharger: InLocationsChargers[]
    ) => {
      if (type === "connector") {
        let totalConnector: any;
        const connectorWithOrder = allConnectors.filter(x => x.order_number === NIndex);
        let temp = 0;
        totalConnector = allConnectors.map( x => {
          if (connectorWithOrder.length > 0 && x.connector_id === connectorWithOrder[0].connector_id){
            x.order_number = allConnectors.filter(item => item.connector_id === uid)[0].order_number
            x.order_number = x.order_number === NIndex ? temp : x.order_number;
          }
          if(x.connector_id === uid){
            temp = x.order_number;
            x.order_number = NIndex;
          }
          return {
            connector_id: x.connector_id,
            evse_uid: Number(currentChargerTab),
            order_number: x.order_number
          };
        });
        let newTotalConnector: InLocationsConnector[] = [];
        totalConnector = {...totalConnector};
        Object.values(totalConnector).forEach((x: any)=> {
          newTotalConnector[x.connector_id] = x;
        });
        setConnectorArray(newTotalConnector);
        // let lasConnector = {
        //   id: 0,
        //   orderNumber: 0,
        // };

        // let firstConnector = {
        //   id: 0,
        //   orderNumber: 0,
        // };

        // const available = connectorArray.map((element, index) =>
        //     element ? { ...element, connector_id: index } : null
        // ).filter((element) => element);
        // if (available.filter((item) => item?.order_number).length > 0) {
        //   let final = available.find((item) => item?.order_number === NIndex);

        //   if (!final) {
        //     //@ts-ignore
        //     final = {
        //       connector_id: allConnectors[NIndex - 1].connector_id,
        //       order_number: allConnectors[NIndex - 1].order_number,
        //     };
        //   }

        //   let first = available.find((item) => item?.connector_id === uid);
        //   if (!first) {
        //     //@ts-ignore
        //     first = {
        //       connector_id: allConnectors[OIndex].connector_id,
        //       order_number: allConnectors[OIndex].order_number,
        //     };
        //   }

        //   if (final && first) {
        //     lasConnector = {
        //       id: final.connector_id,
        //       orderNumber: first.order_number,
        //     };
        //     firstConnector = { id: uid, orderNumber: final.order_number };

        //     const totalConnector = [...connectorArray];
        //     totalConnector[lasConnector.id] = {
        //       ...totalConnector[lasConnector.id],
        //       order_number: lasConnector.orderNumber,
        //     };
        //     totalConnector[firstConnector.id] = {
        //       ...totalConnector[firstConnector.id],
        //       order_number: firstConnector.orderNumber,
        //     };
        //     console.log("totalConnector 3", totalConnector);
        //     setConnectorArray(totalConnector);
        //   }
        // } else {
        //   lasConnector = {
        //     id: allConnectors.filter(x => x.order_number === NIndex)[0].connector_id,
        //     orderNumber: NIndex,
        //   };

        //   firstConnector = {
        //     id: allConnectors[OIndex].connector_id,
        //     orderNumber: NIndex,
        //   };
        // }

        // const itemA: any = {
        //   evse_uid: Number(currentChargerTab),
        //   connector_id: firstConnector.id,
        //   order_number: firstConnector.orderNumber,
        // };
        // const totalConnector = [...connectorArray];
        // totalConnector[firstConnector.id] = { ...itemA };

        // const itemB: any = {
        //   evse_uid: Number(currentChargerTab),
        //   connector_id: lasConnector.id,
        //   order_number: lasConnector.orderNumber,
        // };

        // totalConnector[lasConnector.id] = { ...itemB };
        // console.log("totalConnector 4", totalConnector);
        // setConnectorArray(totalConnector);
      } else {
        let itemA = {
          id: 0,
          orderNumber: 0,
        };

        let itemB = {
          id: 0,
          orderNumber: 0,
        };

        const available = editCharger
          .map((element, index) =>
            element ? { ...element, evse_uid: index } : null
          )
          .filter((element) => element);

        if (available.filter((item) => item?.order_number).length > 0) {
          let final = available.find((item) => item?.order_number === NIndex);

          if (!final) {
            //@ts-ignore
            final = {
              evse_uid: allCharger[NIndex - 1].evse_uid,
              order_number: allCharger[NIndex - 1].order_number,
            };
          }

          let first = available.find((item) => item?.evse_uid === uid);
          if (!first) {
            //@ts-ignore
            first = {
              evse_uid: allCharger[OIndex].evse_uid,
              order_number: allCharger[OIndex].order_number,
            };
          }

          if (final && first) {
            itemB = {
              id: final.evse_uid,
              orderNumber: first.order_number,
            };

            itemA = { id: uid, orderNumber: final.order_number };

            const totalCharger = [...editCharger];
            totalCharger[itemB.id] = {
              ...totalCharger[itemB.id],
              order_number: itemB.orderNumber,
            };
            totalCharger[itemA.id] = {
              ...totalCharger[itemA.id],
              order_number: itemA.orderNumber,
            };
            setEditCharger(totalCharger);
          }
        } else {
          itemB = {
            id: allCharger[NIndex - 1].evse_uid,
            orderNumber: OIndex + 1,
          };

          itemA = {
            id: allCharger[OIndex].evse_uid,
            orderNumber: NIndex,
          };
        }
        const ChargerA: any = {
          evse_uid: itemA.id,
          order_number: itemA.orderNumber,
        };
        const chargerB: any = {
          evse_uid: itemB.id,
          order_number: itemB.orderNumber,
        };

        const totalCharger = [...editCharger];
        totalCharger[itemA.id] = { ...ChargerA };
        totalCharger[itemB.id] = { ...chargerB };
        setEditCharger(totalCharger);
      }
    },

    [currentChargerTab, editCharger]
  );

  const handleBrandsAndModelChange = useCallback(
    (
      key:
        | "model"
        | "brand"
        | "activation_capabilities"
        | "payment_capabilities",
      _index: number,
      uid: string | number,
      value: any
    ) => {
      const index = Number(uid);
      if (key === "brand") {
        if (optionsModel.findIndex((item) => item.key === value) === -1) {
          getModelByCharger(value);
        }
      }
      if (editCharger.find((element) => element?.evse_uid === uid)?.evse_uid) {
        const totalCharger = [...editCharger];
        totalCharger[index] = { ...totalCharger[index], [key]: value };
        setEditCharger(totalCharger);
      } else {
        const newCharger: any = {
          evse_uid: uid,
          [key]: value,
        };
        const totalCharger = [...editCharger];
        totalCharger[index] = { ...newCharger };
        setEditCharger(totalCharger);
      }
    },
    // eslint-disable-next-line
    [editCharger, optionsModel]
  );
  const handleCalendar = ({ week, schedule }: InPropsSchedule) => {
    const days =
      week.length > 0
        ? week.map((item) => (item === "Miércoles" ? "X" : item[0])).join("-")
        : "No Establecido";

    setEditValuesGeneral({
      ...editValuesGeneral,
      regular_hours: schedule,
      days,
    });
  };

  const getModels = (value: string) => {
    const options = optionsModel.find((item) => item.key === value);
    return options ? options.values : [];
  };

  const handleChangeChargers = (id: string) => {
    setCurrentChargerTab(id);
    handleTabChange(0);
  }

  const getChargers = () => {
    if (folio.evses.length < 1) return [];
    const chargerSorted = folio.evses.sort((a, b) =>
      a.order_number > b.order_number ? 1 : -1
    );

    return chargerSorted.map(
      (
        {
          evse_uid,
          order_number: order,
          brand,
          brand_te6,
          model_te6,
          model,
          max_electric_power,
          uso_exclusivo,
          permite_carga_simultanea,
          coordinates,
          directions,
          floor_level,
          physical_reference,
          payment_capabilities,
          activation_capabilities,
        },
        index
      ) => {
        return {
          menuItem: {
            key: `charger_tab-${index}`,
            icon: "plug",
            content: `Cargador (${evse_uid})`,
            onClick: () => handleChangeChargers(evse_uid.toString()),
          },
          render: () => (
            <TabPane className="px-0" style={{ border: "none" }} key={evse_uid}>
              <Form.Group widths="3">
                <Form.Select
                  label="Orden Cargador"
                  value={editCharger[evse_uid]?.order_number || order}
                  onChange={(_, { value }) =>
                    handleChangeOrder(
                      "evse",
                      evse_uid,
                      Number(value),
                      index,
                      [],
                      chargerSorted
                    )
                  }
                  options={functionShared.generateOptionsNumber(
                    chargerSorted.length
                  )}
                  max={chargerSorted.length}
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
                />
                <Form.Input
                  label="Nivel"
                  value={editCharger[evse_uid]?.floor_level === undefined ?  
                    floor_level : editCharger[evse_uid]?.floor_level || undefined}
                  onChange={handleChargersChange("floor_level", evse_uid)}
                  type="number"
                  max={100}
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
                />
                <Form.Input
                  label="Referencia Física"
                  value={
                    editCharger[evse_uid]?.physical_reference ||
                    physical_reference
                  }
                  onChange={handleChargersChange(
                    "physical_reference",
                    evse_uid
                  )}
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
                />
              </Form.Group>
              <Form.Group widths="2">
                <Form.Select
                  multiple
                  label="Capacidades de Pago"
                  options={payments.map((item: any) => ({
                    text: item.value,
                    value: item.value,
                    key: item.id,
                  }))}
                  value={
                    editCharger[evse_uid]?.payment_capabilities ||
                    payment_capabilities
                  }
                  onChange={(_, data) =>
                    handleBrandsAndModelChange(
                      "payment_capabilities",
                      index,
                      evse_uid,
                      data.value
                    )
                  }
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.CPO}
                />

                <Form.Select
                  multiple
                  label="Capacidades de Activación"
                  options={capabilities.map((item: any) => ({
                    text: item.value,
                    value: item.value,
                    key: item.id,
                  }))}
                  value={
                    editCharger[evse_uid]?.activation_capabilities ||
                    activation_capabilities
                  }
                  onChange={(_, data) =>
                    handleBrandsAndModelChange(
                      "activation_capabilities",
                      index,
                      evse_uid,
                      data.value
                    )
                  }
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.CPO}
                />
              </Form.Group>

              {/* Inputs review */}
              <Form.Group widths="2">
                <Form.Select
                  label="Marca"
                  placeholder={brand || brand_te6}
                  value={
                    returnText(
                      editCharger[evse_uid]?.brand || brand,
                      brands.map((item: any) => ({
                        text: item.value,
                        value: item.value,
                        key: item.id,
                      }))
                    ) || brand
                  }
                  error={checkForError(
                    editCharger[evse_uid]?.brand || brand,
                    brands.map((item: any) => ({
                      text: item.value,
                      value: item.value,
                      key: item.id,
                    })),
                    "brand_charger_uid_" + evse_uid
                  )}
                  onChange={(_, data) => {
                    handleBrandsAndModelChange(
                      "brand",
                      index,
                      evse_uid,
                      data.value
                    );
                    deleteErrorInputCount("brand_charger_uid_" + evse_uid);
                  }}
                  options={brands.map((item: any) => ({
                    text: item.value,
                    value: item.value,
                    key: item.id,
                  }))}
                />
                <Form.Select
                  label="Modelo"
                  placeholder={model || model_te6}
                  value={returnText(
                    editCharger[evse_uid]?.model || model,
                    getModels(editCharger[evse_uid]?.brand || brand)
                  )}
                  options={getModels(editCharger[evse_uid]?.brand || brand)}
                  error={checkForError(
                    editCharger[evse_uid]?.model || model,
                    getModels(editCharger[evse_uid]?.brand || brand),
                    "model_charger_uid_" + evse_uid
                  )}
                  onChange={(_, data) => {
                    handleBrandsAndModelChange(
                      "model",
                      index,
                      evse_uid,
                      data.value
                    );
                    deleteErrorInputCount("model_charger_uid_" + evse_uid);
                  }}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Checkbox
                  label="Permite carga simultanea"
                  checked={
                    editCharger[evse_uid]?.permite_carga_simultanea !==
                    undefined
                      ? editCharger[evse_uid]?.permite_carga_simultanea
                      : permite_carga_simultanea
                  }
                  onChange={(_, data) =>
                    handleCheckboxChargersChange(
                      "permite_carga_simultanea",
                      evse_uid,
                      data.checked || false
                    )
                  }
                />
                <Form.Checkbox
                  label="Dominio Privado"
                  checked={
                    editCharger[evse_uid]?.uso_exclusivo !== undefined
                      ? editCharger[evse_uid]?.uso_exclusivo
                      : uso_exclusivo
                  }
                  onChange={(_, data) =>
                    handleCheckboxChargersChange(
                      "uso_exclusivo",
                      evse_uid,
                      data.checked || false
                    )
                  }
                />
              </Form.Group>

              <Form.Group widths="3">
                <Form.Input
                  label="Coordenadas1"
                  value={`${
                    editCharger[evse_uid]?.coordinates?.latitude
                      ? Number(editCharger[evse_uid]?.coordinates?.latitude).toFixed(4)
                      : coordinates.latitude
                  } ,  ${
                    editCharger[evse_uid]?.coordinates?.longitude
                      ? Number(editCharger[evse_uid].coordinates.longitude).toFixed(4)
                      : coordinates.longitude
                  }`}
                  action={{
                    color: "primary",
                    labelPosition: "right",
                    icon: "map marker alternate",
                    content: "Ajustar",
                    onClick: () => {
                      setCurrentIndexCharger(index);
                      setOpenMap(true);
                    },
                  }}
                />
                <Form.Input
                  label="Max. Potencia"
                  value={
                    editCharger[evse_uid]?.max_electric_power === undefined ? max_electric_power :  
                    editCharger[evse_uid]?.max_electric_power || undefined
                  }
                  onChange={handleChargersChange(
                    "max_electric_power",
                    evse_uid
                  )}
                  type="number"
                  min={0}
                  max={max_electric_power * 1.2}
                  disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
                />
              </Form.Group>

              <Form.TextArea
                placeholder="Instrucciones para acceder fisicamente al cargador"
                label="Instrucciones para el cargador"
                rows={5}
                value={editCharger[evse_uid]?.directions || directions}
                onChange={handleChargersChange("directions", evse_uid)}
              />

              <Form.Button
                content="Abrir Galeria"
                icon="external"
                labelPosition="right"
                loading={loadingImg}
                primary
                onClick={() => handleModalCalendar("gallery")}
              />
            </TabPane>
          ),
        };
      }
    );
  };

  const handleChangeInput = useCallback(
    (key: keyof InNewConnectors) => (_value: any) => {
      let value;

      if (["standard", "format", "typeConnector"].includes(key)) {
        value = _value?.value;
      } else {
        const { target } = _value;
        value = Number(target.value);

        if (
          ["max_amperage", "max_voltage"].includes(key) &&
          typeof value === "number"
        ) {
          if (!isNaN(value) && value < 0)
            return toast.info(`El valor mínimo permitido es de (0)`);

          //@ts-ignore
          if (!isNaN(value) && value > MaxValues[key])
            return toast.info(
              //@ts-ignore
              `El valor máximo permitido es de (${MaxValues[key]})`
            );
        }
      }

      setNewConnector({
        ...newConnector,
        [key]: value,
      });
      setNewConnectorError((prevValues) => ({ ...prevValues, [key]: "" }));
    },
    [newConnector]
  );

  const handleConnectorDelete = (uid: number) => {
    const _connector = [...renderConnector];
    const filtered = _connector.map((item) => {
      return {
        evse_uid: item.evse_uid,
        connector: item.connector.filter((item) => item.connector_id !== uid),
      };
    });
    setRenderConnector(filtered);
    setActiveTabConnectorTab(0);
    setArrayNewConnector(filtered);
  };

  const [errorInputCount, setErrorInputCount] = useState<string[]>([]);
  const [updateErrors, setUpdateErrors] = useState(true);

  // Button submit is enabled
  const isValidModelBrandStandart = () => {
    if (updateErrors) {
      for (let charger of folio.evses) {
        if (!charger.model) {
          !errorInputCount.includes("model_charger_uid_" + charger.evse_uid) &&
            setErrorInputCount([
              ...errorInputCount,
              "model_charger_uid_" + charger.evse_uid,
            ]);
        }

        if (!charger.brand) {
          !errorInputCount.includes("brand_charger_uid_" + charger.evse_uid) &&
            setErrorInputCount([
              ...errorInputCount,
              "brand_charger_uid_" + charger.evse_uid,
            ]);
        }

        for (let connect of charger.connectors) {
          if (connect.standard === "Sin Valor") {
            !errorInputCount.includes("connector_id_" + connect.connector_id) &&
              setErrorInputCount([
                ...errorInputCount,
                "connector_id_" + connect.connector_id,
              ]);
          }
        }
      }
    }
  };

  // Delete invalid inputs id
  const deleteErrorInputCount = (id: string) => {
    setUpdateErrors(false);
    setErrorInputCount((prevErrors) => {
      const newErrors = prevErrors.filter((obj) => obj !== id);
      return newErrors;
    });
  };

  useEffect(() => {}, [errorInputCount]);

  // Valid select input -
  const checkForError = (value: any, options: any, id: string) => {
    const isValid = options.some((option: any) => option.value === value);
    return !isValid;
  };

  // Return text right on select input -
  const returnText = (value: string, options: any) => {
    const text = options.find((option: any) => option.value === value);
    return text?.text;
  };

  const returnValue = (value: string, options: any) => {
    const text = options.find((option: any) => option.value === value);
    return text?.value;
  };

  const getConnectors = () => {
    isValidModelBrandStandart();

    const connectors = renderConnector.find(
      (item) => item.evse_uid === Number(currentChargerTab)
    );
    if (!connectors || (connectors && connectors.connector.length < 1))
      return [];
    const sortConnector = connectors.connector.sort((a, b) =>
      a.order_number > b.order_number ? 1 : -1
    );
    const maxOrderNumber = sortConnector.reduce((max, connector) => {
      return connector.order_number > max ? connector.order_number : max;
    }, 0);

    return sortConnector.map(
      (
        {
          connector_id,
          standard,
          connector_standard_te6,
          power_type,
          format,
          max_voltage,
          order_number,
          max_amperage,
          max_electric_power,
        },
        index
      ) => {
        return {
          menuItem: {
            key: `connector ${index}`,
            icon: "plug",
            content: `Conector (${connector_id})`,
            order_number: order_number,
          },
          render: () => (
            <TabPane className="px-0" style={{ border: "none" }} key={index}>
              <Form.Select
                label="Orden Conector"
                value={
                  connectorArray[connector_id]?.order_number || order_number
                }
                options={functionShared.generateOptionsNumber(
                  maxOrderNumber
                )}
                placeholder="Orden Conector"
                onChange={(_, { value }) =>
                  handleChangeOrder(
                    "connector",
                    connector_id,
                    Number(value),
                    index,
                    sortConnector,
                    []
                  )
                }
                disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE || connector_id >= 100000}
              />
              {/* Standard review */}
              <Form.Group widths="3">
                <Form.Select
                  label="Tipo Conector"
                  placeholder={standard || connector_standard_te6}
                  options={MOCK_INFO_CHARGERS.standard}
                  error={checkForError(
                    connectorArray[connector_id]?.standard || standard,
                    MOCK_INFO_CHARGERS.standard,
                    "connector_id_" + connector_id
                  )}
                  value={returnValue(connectorArray[connector_id]?.standard, MOCK_INFO_CHARGERS.standard) || returnValue(standard, MOCK_INFO_CHARGERS.standard)}
                  // onChange={(_, data) => {
                  //   setConnectorArray((prevConnectorInfo) => {
                  //     const newConnectorInfo = [...prevConnectorInfo];
                  //     newConnectorInfo[connector_id] = {
                  //       ...newConnectorInfo[connector_id],
                  //       standard: data.value?.toString() || "",
                  //       connector_id: connector_id,
                  //       evse_uid: Number(currentChargerTab),
                  //       format: 'CABLE',
                  //       power_type: MOCK_INFO_CHARGERS.standard.filter(x => x.value === data.value?.toString())[0]?.type_connector,
                  //     };
                  //     return newConnectorInfo;
                  //   });
                  //   deleteErrorInputCount("connector_id_" + connector_id);
                  //   if(errorInputCount.includes("connector_format_id_" + connector_id)){
                  //     deleteErrorInputCount("connector_format_id_" + connector_id); 
                  //   }
                  //   if(MOCK_INFO_CHARGERS.multiple_format.includes(String(data.value))){
                  //     addErrorInputCount("connector_format_id_" + connector_id); 
                  //   }
                  //   console.log("error:", errorInputCount);
                  // }}
                  onChange={(_, data) => {
                    setConnectorArray((prevConnectorInfo) => {
                      const newConnectorInfo = [...prevConnectorInfo];
                      newConnectorInfo[connector_id] = {
                        ...newConnectorInfo[connector_id],
                        standard: data.value?.toString() || "",
                        connector_id: connector_id,
                        evse_uid: Number(currentChargerTab),
                        format: 'CABLE',
                        power_type: MOCK_INFO_CHARGERS.standard.filter(x => x.value === data.value?.toString())[0]?.type_connector,
                      };
                      return newConnectorInfo;
                    });

                    setErrorInputCount((prevErrors) => {
                      let newErrors = prevErrors.filter((error) => error !== "connector_id_" + connector_id);
                      if (newErrors.includes("connector_format_id_" + connector_id)) {
                        newErrors = newErrors.filter((error) => error !== "connector_format_id_" + connector_id);
                      }
                      if(MOCK_INFO_CHARGERS.multiple_format.includes(String(data.value))){
                        newErrors.push("connector_format_id_" + connector_id); 
                      }
                      setUpdateErrors(false);
                      return newErrors;
                    });
                  }}
                />
                <Form.Select
                  label="Formato"
                  value={connectorArray[connector_id]?.format || format}
                  placeholder="Formato"
                  error={
                    connectorArray[connector_id]?.format === "" || format === "" || errorInputCount.includes("connector_format_id_" + connector_id)
                  }
                  options={functionShared.getFormatConnector(
                    connectorArray[connector_id]?.standard || standard
                  )}
                  onClick={() => {
                    deleteErrorInputCount(
                      "connector_format_id_" + connector_id
                    );
                  }}
                  onChange={(_, data) => {
                    setConnectorArray((prevConnectorInfo) => {
                      const newConnectorInfo = [...prevConnectorInfo];
                      newConnectorInfo[connector_id] = {
                        ...newConnectorInfo[connector_id],
                        format: data.value?.toString() + "",
                        connector_id: connector_id,
                        evse_uid: Number(currentChargerTab),
                      };
                      return newConnectorInfo;
                    });
                    deleteErrorInputCount(
                      "connector_format_id_" + connector_id
                    );
                  }}
                />
                <Form.Input
                  label="Tipo de potencia"
                  value={
                    connectorArray[connector_id]?.power_type || power_type
                  }
                  placeholder="Tipo de potencia"
                  disabled ={true}
                />
              </Form.Group>
              {/* 
              <Form.Group widths="3">
              <Form.Input
                  label="Voltaje"
                  value={connectorArray[connector_id]?.voltage || voltage}
                  placeholder="Voltaje"
                  onChange={handleConnectorChange("voltage", connector_id)}
                  type="number"
                  min={0}
                /> */}

              {/* <Form.Input
                  label="Amperaje"
                  value={connectorArray[connector_id]?.amperage || amperage}
                  placeholder={"Amperaje"}
                  onChange={handleConnectorChange("amperage", connector_id)}
                  type="number"
                  min={0}
                /> 
              </Form.Group> */}
              <Form.Group widths="3">
                <Form.Input
                  label="Voltaje Máximo"
                  value={
                    connectorArray[connector_id]?.max_voltage === undefined ? max_voltage : connectorArray[connector_id]?.max_voltage || undefined
                  }
                  placeholder="Voltaje Máximo"
                  onChange={handleConnectorChange("max_voltage", connector_id)}
                  type="number"
                  min={0}
                  max={1500}
                />
                <Form.Input
                  label="Amperaje Máximo"
                  value={
                    connectorArray[connector_id]?.max_amperage === undefined ? max_amperage : connectorArray[connector_id]?.max_amperage || undefined
                  }
                  placeholder={"Amperaje Máximo "}
                  onChange={handleConnectorChange("max_amperage", connector_id)}
                  type="number"
                  min={0}
                  max={600}
                />

                <Form.Input
                  label="Max. Potencia"
                  value={
                    connectorArray[connector_id]?.max_electric_power === undefined ? max_electric_power :
                    connectorArray[connector_id]?.max_electric_power || undefined
                  }
                  placeholder={"Max. Potencia"}
                  onChange={handleConnectorChange(
                    "max_electric_power",
                    connector_id,
                    max_electric_power
                  )}
                  type="number"
                  min={1}
                  // max={max_electric_power * 1.2}
                />
              </Form.Group>
              {profile && profile.currentRole?.nameRol === ROLES_NAME.SEC && (
                <Fragment>
                  <Divider className="mt-2" />
                  <Form.Group className="mt-2 d-flex justify-center">
                    <Form.Button
                      content="Eliminar conector"
                      secondary
                      loading={false}
                      disabled={connectors.connector.length < 2}
                      onClick={() => handleConnectorDelete(connector_id)}
                    />
                    <Form.Button
                      content="Agregar conector"
                      primary
                      loading={false}
                      onClick={() => setOpenModal(true)}
                    />
                  </Form.Group>
                </Fragment>
              )}
            </TabPane>
          ),
        };
      }
    );
  };

  const handleQuantityConnector = async () => {
    try {
      const validConnectors: any = connectorArray.filter((element: any) => element);
      if (arrayNewConnector.length < 1) return;

      const filteredData = arrayNewConnector.map((item) => ({
        location_id: folio.location_id,
        evses: [
          {
            evse_uid: item.evse_uid,

            connectors: item.connector.map((connector, index) => {
              if (connector.connector_id >= 10000) {
                const { connector_id, ...rest } = connector;
                return {
                  ...rest,
                  soc: 100,
                  tariffs: [],
                };
              } else {
                const index = validConnectors.findIndex((obj: any) => obj.connector_id === connector.connector_id);
                if(index >= 0){
                  connector.format = validConnectors[index].format;
                  connector.power_type = validConnectors[index].power_type;
                  connector.standard = validConnectors[index].standard;
                }
                return connector;
              }
            }),
          },
        ],
      }));
      const allFetching = filteredData.map((body) =>
        handleUpdateT6LocationsService(body)
      );
      await Promise.all(allFetching);
      toast.success(
        `Modificaciones realizadas con exito.`
      );
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
      setLoading(false);
      onClose();
    }
  };

  const getModelByCharger = async (value: number) => {
    try {
      const item = brands.find((element: any) => element.value === value);
      if (!item) return;
      const model = await getModelByBrandsIdService(item.id);
      if (model.length > 0) {
        setOptionsModel([
          ...optionsModel,
          {
            key: item.value,
            values: model.map((item) => ({
              id: item.id,
              text: item.value,
              value: item.value,
            })),
          },
        ]);
      }
      return [];
    } catch (error) {
      toast.error(`Ocurrio un error al obtener los conectores de ${value}`);
      return [];
    }
  };

  const handleUpdateFolioSEC = async () => {
    try {

      let validConnectors: any = connectorArray.filter((element: any) => element);
      const validChargers = editCharger.filter((item) => item);

      const connectors: any = renderConnector.find(
        (item) => item.evse_uid === Number(currentChargerTab)
      );

      let sorteConnector = connectors.connector.map((x: any, value: number) => {
        if (value < connectors.connector.length - arrayNewConnector.length){
          const index = validConnectors.findIndex((obj: any) => obj.connector_id === x.connector_id);
          if(index >= 0){
            return {
              connector_id: validConnectors[index].connector_id,
              evse_uid: validConnectors[index].evse_uid,
              order_number: validConnectors[index]?.order_number || x.order_number,
              format: validConnectors[index]?.format || x.format,
              power_type: validConnectors[index]?.power_type || x.power_type,
              standard: validConnectors[index]?.standard || x.standard
            }
          }
          return {
            order_number: x.order_number,
            connector_id: x.connector_id,
            evse_uid: connectors.evse_uid,
            format: x.format,
            power_type: x.power_type,
            standard: x.standard
          }
        }
        return undefined;
      })
      sorteConnector = sorteConnector.filter((value: any) => value !== undefined);
      validConnectors = sorteConnector;
      const formatConnector = transformedData(validConnectors);
      const evses = getFormatEvses(validChargers, formatConnector);

      const { days, method, regular_hours, ...infoFolio } = editValuesGeneral;
      let locations: any = { ...infoFolio };

      if (
        JSON.stringify(folio.opening_times.regular_hours) !==
          JSON.stringify(editValuesGeneral.regular_hours) &&
        regular_hours &&
        regular_hours[0].period_begin
      ) {
        Object.assign(locations, {
          opening_times: {
            regular_hours: editValuesGeneral.regular_hours,
          },
        });
      }

      if (evses.length > 0) {
        Object.assign(locations, {
          evses,
        });
      }
      if (arrayNewConnector.length === 0) {
        locations = {
          ...locations,
          location_id: folio.location_id,
          api_updates_enabled: generalInfo.method === DEFAULT_METHOD.API,
        };
        //Agregado para sobreescribir los conectores con los nuevos valores
        if (!locations?.evses[0]?.connectors) {
          locations = {
            ...locations,
            evses: formatAllEvsesForSEC(locations),
          };
        }
        const data = await handleUpdateT6LocationsService(locations);
        if (data)
          toast.success("Petición actualización de datos realizada con éxito");
      }
    } catch (error) {
      toast.error("Ocurrio un error al intentar actualziar el Folio");
    }
  };

  const initSubmit = async () => {
    try {
      if (isLoading) return;
      setLoading(true);
      if (profile && profile.currentRole?.nameRol === ROLES_NAME.SEC) {
        if (fileUpload.folio) {
          await handleUpload(fileUpload.folio, "locations", folio.location_id);
        }

        await handleUpdateFolioSEC();
        if (arrayNewConnector.length > 0) {
          await handleQuantityConnector();
        }
        setLoading(false);
        onClose();
      } else {
        return await onSubmitOPC();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSubmitOPC = async () => {
    try {
      const hasChanges =  !((folio.api_updates_enabled && generalInfo.method === DEFAULT_METHOD.API) || 
                          (!folio.api_updates_enabled && generalInfo.method === DEFAULT_METHOD.MANUAL));
      const validConnectors: any = connectorArray.filter((element) => element);
      const validChargers = editCharger.filter((item) => item);

      const formatConnector = transformedData(validConnectors);
      const evses = getFormatEvses(validChargers, formatConnector);
      const { method, days, regular_hours, ...infoFolio } = editValuesGeneral;
      let fields_request: any = { ...infoFolio };

      if (
        JSON.stringify(folio.opening_times.regular_hours) !== JSON.stringify(regular_hours) &&
        regular_hours && regular_hours[0]?.period_begin
      ) {
        Object.assign(fields_request, {
          opening_times: {
            regular_hours: editValuesGeneral.regular_hours,
          },
        });
      }

      if (evses.length > 0) {
        Object.assign(fields_request, {
          evses,
        });
      }
      if (fileUpload.folio) {
        await handleUpload(fileUpload.folio, "locations", folio.location_id);
      }
      if (fileUpload.config) {
        await handleUpload(fileUpload.config, "locations", folio.location_id);
      }

      if (errorInputCount.length) {
        toast.info(
          "Existen " +
            errorInputCount.length +
            " campos pendientes por completar"
        );
        return setLoading(false);
      }

      if (
        Object.values(fields_request).length < 1 &&
        Object.values(connectorArray).length < 1 && !hasChanges && !fileUpload.folio && !fileUpload.config
      ) {
        toast.info("No existen cambios a guardar");
        return setLoading(false);
      }

      if (Object.values(fields_request).length > 0) {
        fields_request = {
          ...fields_request,
          location_id: folio.location_id,
        };
        const data = await handleConfirmInfoService(
          Number(profile?.userId),
          folio.location_id,
          "",
          fields_request
        );

        if (data)
          toast.success("Petición actualización de datos realizada con éxito");
      }

      if (hasChanges) {
        fields_request = {
          api_updates_enabled: !folio.api_updates_enabled,
          location_id: folio.location_id,
        };

        const data = await handleConfirmInfoService(
          Number(profile?.userId),
          folio.location_id,
          "Solicitud cambio de método de actualización a Manual",
          fields_request,
          5
        );

        if (data)
          toast.success("Petición cambio de actualización realizada con éxito");
      }
      setLoading(false);
      onClose();
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const formatAllEvsesForSEC = (resultData: InLocationsDetails) => {
    const removeNulls = (obj: any) => {
      // Elimina claves con valores null o undefined de un objeto
      return Object.keys(obj)
        .filter((key) => obj[key] !== null && obj[key] !== undefined)
        .reduce((acc: any, key: any) => {
          acc[key] = obj[key];
          return acc;
        }, {});
    };
    const baseArray = folio.evses;
    return baseArray.map((evse) => {
      // Busca el objeto de actualización correspondiente en resultData
      const updatedEVSE = resultData.evses.find(
        (update) => update.evse_uid === evse.evse_uid
      );
      if (updatedEVSE) {
        // Crea un objeto con las propiedades actualizadas, sin valores null
        const updatedProperties = removeNulls(updatedEVSE);
        // Combina los valores originales con los valores actualizados, eliminando valores null
        return removeNulls({ ...evse, ...updatedProperties });
      }

      // Si no existe actualización, retorna el objeto original sin valores null
      return removeNulls(evse);
    });
  };

  const getFormatEvses = (chargers: any[], connectors: any) => {
    if (chargers.length < 1 && connectors[0].evses.length < 1) return [];
    const result = [];
    for (const itemConnector of connectors) {
      const indexConnectorUID = itemConnector.evses.map(
        (item: any) => item.evse_uid
      );

      for (const itemCharger of chargers) {
        const index = indexConnectorUID.indexOf(itemCharger.evse_uid);
        if (index === -1) {
          result.push(itemCharger);
        } else {
          itemCharger.connectors = itemConnector.evses[index].connectors || [];
          result.push(itemCharger);
          continue;
        }
      }
      const chargerAvailable = chargers.map((element) => element.evse_uid);
      const indexConnector = indexConnectorUID.filter(
        (item: any) => !chargerAvailable.includes(item)
      );

      if (indexConnector.length === 1) {
        const connector = itemConnector.evses.find(
          (element: any) => element.evse_uid === indexConnector[0]
        );
        result.push(connector);
      }
    }

    return result;
  };

  const transformedData = (data: InLocationsConnectorExtends[]) => {
    const evsesMap: { [key: number]: InLocationsConnector[] } = {};

    // Agrupar conectores por evse_uid
    for (const item of data) {
      const { evse_uid, ...connectorData } = item;
      if (!evsesMap[evse_uid]) {
        evsesMap[evse_uid] = [];
      }

      evsesMap[evse_uid].push(connectorData);
    }

    // Construir el array de evses
    const evses: any = Object.entries(evsesMap).map(
      ([evse_uid, connectors]) => ({
        evse_uid: parseInt(evse_uid),
        connectors,
      })
    );

    return [{ evses }];
  };

  const handleImageFolio = () => {
    if (
      (editValuesGeneral?.images && editValuesGeneral?.images.length > 0) ||
      fileUpload.folio
    ) {
      setImagePreview(true);
    } else {
      fileFolioRef.current?.click();
    }
  };

  const handleTabChange = (activeIndex: number) => {
    console.log(activeIndex);
    setActiveTabConnectorTab(activeIndex);
  };

  const panes = [
    {
      menuItem: "Información General",
      render: () => (
        <TabPane>
          <Form>
            <label className="text-bold">
              Seleccione el método de actualización de los datos variables
            </label>
            <Form.Group className="mt-1" grouped>
              <Form.Field
                control={Checkbox}
                label={"Manual"}
                name={"Uno"}
                className="text-bold"
                checked={generalInfo.method === DEFAULT_METHOD.MANUAL}
                type="checkbox"
                onChange={() =>
                  setGeneralInfo({
                    ...generalInfo,
                    method: DEFAULT_METHOD.MANUAL,
                  })
                }
                disabled={
                  !folio.api_updates_enabled &&
                  profile?.currentRole?.nameRol !== ROLES_NAME.SEC
                    ? true
                    : false
                }
              />

              <Form.Field
                control={Checkbox}
                label={"Via API"}
                name={"Uno"}
                className="text-bold"
                checked={generalInfo.method === DEFAULT_METHOD.API}
                type="checkbox"
                onChange={() =>
                  setGeneralInfo({ ...generalInfo, method: DEFAULT_METHOD.API })
                }
                disabled={
                  !folio.api_updates_enabled &&
                  profile?.currentRole?.nameRol !== ROLES_NAME.SEC
                    ? true
                    : false
                }
              />
              {generalInfo.method === DEFAULT_METHOD.MANUAL &&
                profile?.currentRole?.nameRol !== ROLES_NAME.SEC && (
                  <Fragment>
                    <input
                      type="file"
                      ref={fileConfigRef}
                      style={{ display: "none" }}
                      accept="application/pdf"
                      onChange={({ target }) =>
                        setFileUpload({
                          ...fileUpload,
                          config: target?.files?.[0] || ({} as File),
                        })
                      }
                    />
                    <Button
                      className="mt-1"
                      content={
                        fileUpload.config
                          ? "Reemplazar archivo"
                          : "Subir archivo"
                      }
                      primary
                      onClick={() => fileConfigRef.current?.click()}
                    />
                  </Fragment>
                )}
            </Form.Group>
            <Divider />
            <Form.Group widths={"2"}>
              <Form.Input
                label="Nombre Instalación"
                value={editValuesGeneral.name || generalInfo.name}
                onChange={({ target: { value } }) =>
                  setEditValuesGeneral({ ...editValuesGeneral, name: value })
                }
              />
              <Form.Input label="ID Instalación" value={folio.location_id} />
            </Form.Group>

            <Form.Group widths={"3"}>
              <Form.Select
                label="Tipo estacionamiento"
                options={parking_types.map((item: any) => {
                  return {
                    key: item.id,
                    text: item.value,
                    value: item.value,
                  };
                })}
                value={
                  editValuesGeneral.parking_type || generalInfo.parking_type
                }
                onChange={(_, { value }) =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    parking_type: String(value),
                  })
                }
                disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
              />
              <Form.Select
                label="Zona horaria"
                options={OPTIONS_TIMEZONE}
                value={editValuesGeneral.time_zone || generalInfo.time_zone}
                onChange={(_, { value }) =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    time_zone: String(value),
                  })
                }
                disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
              />
              <Form.Input
                label="Número soporte"
                value={
                  editValuesGeneral.support_phone_number ||
                  generalInfo.support_phone_number ||
                  0
                }
                onChange={({ target: { value } }) =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    support_phone_number: value.replace(/[^0-9]/g, ""),
                  })
                }
              />
            </Form.Group>

            {/* <Form.Group widths={"2"}>
              <Form.Input
                label="Horarios excepcionales"
                value={getExceptionalDay(folio.opening_times)}
              />
            </Form.Group> */}
            <Form.Group widths={"2"}>
              <Form.Checkbox
                label="Atención 24 horas"
                checked={editValuesGeneral.twentyfourseven !== undefined ? editValuesGeneral.twentyfourseven : generalInfo.twentyfourseven}
                onChange={() =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    twentyfourseven:
                    editValuesGeneral.twentyfourseven !== undefined ? !editValuesGeneral.twentyfourseven : !generalInfo.twentyfourseven
                  })
                }
              />
              <Form.Checkbox
                label="Accesible fuera de horario"
                checked={editValuesGeneral.charging_when_closed !== undefined ? editValuesGeneral.charging_when_closed : generalInfo.charging_when_closed}
                onChange={() =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    charging_when_closed:
                      editValuesGeneral.charging_when_closed !== undefined ? !editValuesGeneral.charging_when_closed : !generalInfo.charging_when_closed,
                  })
                }
                disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
              />
            </Form.Group>
            <Form.TextArea
              label="Instrucciones"
              value={
                editValuesGeneral.directions !== undefined
                  ? editValuesGeneral.directions
                  : generalInfo.directions
              }
              onChange={({ target: { value } }) =>
                setEditValuesGeneral({
                  ...editValuesGeneral,
                  directions: value,
                })
              }
              disabled={profile?.currentRole?.nameRol === ROLES_NAME.PSE}
            />

            <Form.Group widths={"3"}>
              <Form.Input
                label="Horario de atención"
                disabled={editValuesGeneral.twentyfourseven !== undefined ? editValuesGeneral.twentyfourseven : generalInfo.twentyfourseven}
                value={editValuesGeneral.days || generalInfo.days}
                action={{
                  color: "primary",
                  labelPosition: "right",
                  icon: "calendar alternate",
                  content: "Ajustar",
                  onClick: () => handleModalCalendar("schedule"),
                }}
              />
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={fileFolioRef}
                style={{ display: "none" }}
                onChange={({ target }) =>
                  setFileUpload({
                    ...fileUpload,
                    folio: target?.files?.[0] || ({} as File),
                  })
                }
              />

              <Form.Button
                content={
                  editValuesGeneral?.images?.length > 0 || fileUpload.folio
                    ? "Click para previsualizar"
                    : "Click para agregar"
                }
                color="blue"
                label="Imagen Folio"
                onClick={handleImageFolio}
                icon={{ name: "file image outline", color: "white" }}
                loading={loadingImg}
              />
            </Form.Group>
            <div className="d-flex justify-between mx-2">
              <span className="text-bold">
                Fecha Revisión:
                <span className="ml-2 text-bold">
                  {/* {moment(folio.revision_date).format("DD-MMMM-YYYY HH:mm:ss")} */}
                  {folio.revision_date}
                </span>
              </span>

              <span className="text-bold">
                Tipo de instalación:
                <span className="ml-2 text-bold">
                  {folio.charging_instalation_type || "---"}
                </span>
              </span>
            </div>
          </Form>
        </TabPane>
      ),
    },
    {
      menuItem: "Información Ubicación",
      render: () => (
        <TabPane>
          <Form>
            <Form.Group widths={"3"}>
              <Form.Input
                label="Dirección"
                value={editValuesGeneral.address || generalInfo.address}
                onChange={({ target: { value } }) =>
                  setEditValuesGeneral({ ...editValuesGeneral, address: value })
                }
              />
              <Form.Input label="Región" value={folio.region} />
              <Form.Input label="Comuna" value={folio.commune} />
            </Form.Group>

            <label className="text-bold">Coordenadas</label>
            <Segment placeholder>
              <Map
                latitude={generalInfo.coordinates.latitude}
                longitude={generalInfo.coordinates.longitude}
                onlyRead={false}
                showControl
                name={folio.name}
                onMapClick={({ lat, lng }) =>
                  setEditValuesGeneral({
                    ...editValuesGeneral,
                    coordinates: {
                      latitude: lat,
                      longitude: lng,
                    },
                  })
                }
              />
            </Segment>
          </Form>
        </TabPane>
      ),
    },
    {
      menuItem: "Información Cargadores",
      render: () => (
        <TabPane>
          <Form className="form-tab-content">
            <Tab panes={getChargers()} />
            <Label basic className="px-0 mb-1" size="large" color="blue">
              Datos de Conectores
            </Label>
            {getConnectors().length < 1 ? (
              <>
                <span className="d-flex text-bold text-center">
                  No existen conectores
                </span>
                {profile && profile.currentRole?.nameRol === ROLES_NAME.SEC && (
                  <Fragment>
                    <Divider className="mt-2" />
                    <Form.Group className="mt-2 d-flex justify-center">
                      <Form.Button
                        content="Agregar conector"
                        primary
                        loading={false}
                        onClick={() => setOpenModal(true)}
                      />
                    </Form.Group>
                  </Fragment>
                )}
              </>
            ) : (
              <Tab
                panes={getConnectors()}
                activeIndex={activeConnectorTab}
                onTabChange={(_, data) =>
                  handleTabChange(Number(data?.activeIndex || 0))
                }
              />
            )}
          </Form>
        </TabPane>
      ),
    },
  ];
  const handleImageDelete = () => {
    const files = { config: fileUpload.config } as InFileUpload;
    setFileUpload(files);
    setEditValuesGeneral({ ...editValuesGeneral, images: [] });
    toast.info("La imagen del folio ha sido removida con exito");
    setImagePreview(false);
  };

  const handleSubmitConector = async () => {
    try {
      let { isValid, errors } = await handleValidationAddConectorSchema(
        newConnector
      );
      if (newConnector?.amperage > newConnector?.max_amperage){
        //@ts-ignore
        errors.amperage = "El amperage no puede ser mayor al amperage maximo";
        isValid = false
      }
      if (newConnector?.voltage > newConnector?.max_voltage){
        //@ts-ignore
        errors.voltage = "El voltaje no puede ser mayor al voltaje maximo";
        isValid = false
      }
      if (newConnector?.electric_power > newConnector?.max_electric_power){
        //@ts-ignore
        errors.electric_power = "La potencia no puede ser mayor a la potencia maxima";
        isValid = false
      }
      if (isValid) {
        
        const newOrder = getConnectors().reduce((max, connector) => {
          return connector.menuItem.order_number > max ? connector.menuItem.order_number : max;
        }, 0) + 1;
        const addedId: InNewConnectors = {
          ...newConnector,
          connector_id: Number(`10000${arrayNewConnector.length}`),
          order_number: newOrder,
          identificador_conector: `identificador_provisorio_${newOrder}`,
          status: "AVAILABLE",
          power_type: MOCK_INFO_CHARGERS.standard.filter(x => x.value === newConnector.standard?.toString())[0]?.type_connector,
        };

        let body = renderConnector.find(
          (element) => element.evse_uid === Number(currentChargerTab)
        );

        if (body) {
          const newRender = renderConnector.filter(
            (item) => item.evse_uid !== body?.evse_uid
          );
          const newAdded = arrayNewConnector.filter(
            (item) => item.evse_uid !== body?.evse_uid
          );
          body.connector.push(addedId);
          newRender.push(body);
          newAdded.push(body);
          setRenderConnector(newRender);
          setArrayNewConnector(newAdded);
        } else {
          body = {
            evse_uid: Number(currentChargerTab),
            connector: [addedId],
          };
          setRenderConnector([...renderConnector, body]);
          setArrayNewConnector([...arrayNewConnector, body]);
        }

        setOpenModal(false);
        setNewConnector({} as InNewConnectors);
      } else {
        //@ts-ignore
        setNewConnectorError(errors);
      }
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
    }
  };

  return (
    <Fragment>
      <ModalHeader
        content={
          showHeader ? `Confirme los datos del IRVE - ${folio.folio_IRVE}` : ""
        }
      />
      <ModalContent content={<Tab panes={panes} />} />
      <ModalActions
        className="text-center"
        content={
          <div className="d-flex justify-center">
            <CommonButton
              label="Guardar"
              onClick={initSubmit}
              loading={isLoading}
            />
            <CommonButton label="Cerrar" mode="secondary" onClick={onClose} />
          </div>
        }
      />
      <ScheduleSelectionModal
        open={showModal.schedule}
        initialSchedule={folio.opening_times.regular_hours}
        onClose={() => setShowModal({ ...showModal, schedule: false })}
        onSuccess={handleCalendar}
      />
      <GalleryModal
        open={showModal.gallery}
        title={currentChargerTab}
        onClose={() => setShowModal({ ...showModal, gallery: false })}
        initialFile={
          arrayImagesCharger.find((item) => item.id === Number(currentChargerTab))
            ?.images
        }
      />
      <Modal
        open={imagePreview}
        onClose={() => setImagePreview(false)}
        size="mini"
      >
        <Modal.Header content={`Imagen Folio ${folio.folio_IRVE}`} />
        <Modal.Content image>
          {(editValuesGeneral?.images?.length > 0 || fileUpload?.folio) &&
            imagePreview && (
              <Image
                size="medium"
                src={
                  editValuesGeneral?.images?.length > 0
                    ? editValuesGeneral.images[
                        editValuesGeneral.images.length - 1
                      ]
                    : URL.createObjectURL(fileUpload.folio)
                }
                wrapped
              />
            )}
        </Modal.Content>
        <Modal.Actions className="text-center">
          <Button color="red" onClick={handleImageDelete}>
            Eliminar
          </Button>
          <Button primary onClick={() => setImagePreview(false)}>
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
      <CommonModal
        title="Datos nuevo conector"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        content={
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group widths="2">
              <Form.Select
                label="Tipo Conector"
                placeholder={"Tipo Conector"}
                options={MOCK_INFO_CHARGERS.standard}
                value={newConnector.standard}
                error={newConnectorError.standard || undefined}
                onChange={(_, data) => {
                  setNewConnectorError((prevValues) => ({
                    ...prevValues,
                    standard: "",
                  }));
                  setNewConnector({
                    ...newConnector,
                    standard: String(data.value),
                    format: "",
                  });
                }}
              />
              <Form.Select
                label="Formato"
                placeholder="Formato"
                options={functionShared.getFormatConnector(
                  newConnector.standard
                )}
                value={newConnector.format}
                error={newConnectorError.format || undefined}
                onChange={(_, data) => {
                  setNewConnectorError((prevValues) => ({
                    ...prevValues,
                    format: "",
                  }));

                  setNewConnector({
                    ...newConnector,
                    format: String(data.value),
                  });
                }}
              />
            </Form.Group>

            <Form.Group widths="3">
              <Form.Input
                label="Voltaje"
                placeholder="Voltaje"
                type="number"
                min={0}
                value={newConnector.voltage || undefined}
                error={newConnectorError.voltage || undefined}
                onChange={handleChangeInput("voltage")}
              />
              <Form.Input
                label="Voltaje Máximo"
                placeholder="Voltaje Máximo"
                type="number"
                min={0}
                max={1500}
                value={newConnector.max_voltage || undefined}
                error={newConnectorError.max_voltage || undefined}
                onChange={handleChangeInput("max_voltage")}
              />
              <Form.Input
                label="Amperaje"
                placeholder={"Amperaje"}
                type="number"
                min={0}
                value={newConnector.amperage || undefined}
                error={newConnectorError.amperage || undefined}
                onChange={handleChangeInput("amperage")}
              />
            </Form.Group>
            <Form.Group widths="3">
              <Form.Input
                label="Amperaje Máximo"
                placeholder={"Amperaje Máximo "}
                min={0}
                max={600}
                type="number"
                error={newConnectorError.max_amperage || undefined}
                value={newConnector.max_amperage || undefined}
                onChange={handleChangeInput("max_amperage")}
              />

              <Form.Input
                label="Potencia"
                placeholder="Potencia"
                type="number"
                min={0}
                value={newConnector.electric_power || undefined}
                error={newConnectorError.electric_power || undefined}
                onChange={handleChangeInput("electric_power")}
              />
              <Form.Input
                label="Max. Potencia"
                placeholder={"Max. Potencia"}
                type="number"
                min={0}
                value={newConnector.max_electric_power || undefined}
                error={newConnectorError.max_electric_power || undefined}
                onChange={handleChangeInput("max_electric_power")}
              />
            </Form.Group>
            <Divider className="mt-2" />
            <Form.Button
              content="Guardar Conector"
              primary
              className="text-center"
              onClick={handleSubmitConector}
            />
          </Form>
        }
      />
      <Modal
        open={openMap}
        onClose={() => setOpenMap(false)}
        style={{ height: "60%" }}
      >
        <ModalHeader content={`Coordenadas Cargador ${currentChargerTab}`} />
        <ModalContent>
          <Segment placeholder>
            <Map
              latitude={
                Number(
                  editCharger[currentIndexCharger]?.coordinates?.latitude
                ) ||
                Number(folio.evses[currentIndexCharger]?.coordinates?.latitude)
              }
              longitude={
                Number(
                  editCharger[currentIndexCharger]?.coordinates?.longitude
                ) ||
                Number(folio.evses[currentIndexCharger]?.coordinates?.longitude)
              }
              name={`Cargador ${currentChargerTab}`}
              onMapClick={(data) => setCurrentCoords(data)}
              onlyRead={false}
            />
            {currentCoords.lat !== 0 && (
              <span className="text-center text-bold my-1">
                Lat: {currentCoords.lat.toFixed(4)}, Lng:
                {currentCoords.lng.toFixed(4)}
              </span>
            )}
          </Segment>
        </ModalContent>
        <ModalContent className="text-center">
          <CommonButton label="Guardar" onClick={handleMapChargerChange} />
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ModalConfirmation;
